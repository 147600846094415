import './custom.scss';
import {Route, Routes} from 'react-router-dom'
import Homepage from './Homepage';

function App() {
  return(
    <div>
      <Routes>
        <Route exact path="/" element={<Homepage/>}/>    
      </Routes>
    </div>
  )
}

export default App;
