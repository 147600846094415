import {Container, Row, Form, Col, Button, Alert} from 'react-bootstrap'
import { useState } from 'react';
import sptfy from './yours';
import validateUrl from './utils';

const Homepage = () =>{
    const [spotifyURL, setSpotifyURL] = useState("");
    const [shortURL, setShortURL] = useState(""); 
    const [isUrlValid, setIsUrlValid] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const getShortenedURL = (e) =>{
      e.preventDefault();
      if(!validateUrl(spotifyURL)){
        setErrorMsg("Please enter a valid spotify link");
      }

      else{
        setIsUrlValid(true);  
        sptfy.shorten(spotifyURL, function(result, response){
          console.log(result.shorturl)
          setShortURL(result.shorturl)
        })
      }
    }


    return (
      <Container className='mt-3 text-center'>
          <h1 className='mt-2 fw-bold'>Spotify URL Shortener </h1>
          <p>Paste your long Spotify URL and click to shorten.</p>          
          <Row className='justify-content-center'>
            <Col lg={5} md={6} sm={12}>
              <Form className='mt-1' onSubmit={getShortenedURL}>
                <Form.Group className='mb-1 mt-1'>
                  <Form.Control type='text' placeholder='Spotify URL' onChange={e => setSpotifyURL(e.target.value)}/>
                </Form.Group>    
                {isUrlValid === true &&
                  <Alert className='mt-2 mb-1' variant='success'>
                    {shortURL}
                  </Alert>
                }

                {errorMsg !== "" &&
                  <Alert className='mt-2 mb-1' variant='danger'>
                  {errorMsg}
                </Alert>
                }

              <Button className='mt-2' variant='dark' type='submit'>Shorten</Button>
              </Form>
            </Col>
          </Row>
      </Container>
    );
}

export default Homepage;